
<!-- eslint-disable -->
<template>
  <v-container
    id="user-profile"
    fluid
    tag="section"
  >
    <v-row justify="center">
      <v-col
        cols="12"
        md="8"
      >
        <span class="text-h2 darkgrey--text">Upload User Avatar:</span>
<!-- *************** Upload File Form ******************** 
     *** Raises UploadFileForm as a modal when edit avatar is clicked   -->
          <v-dialog
            v-model="dialog1" max-width="500" persistent>
            <template v-slot:activator="{ on, attrs }">
              <v-tooltip bottom>
                <template v-slot:activator="{on: tooltip}">
                  <v-btn
                    class="ml-2"
                    min-width="0"
                    text
                    v-bind="attrs"
                    v-on="{ ...on, ...tooltip }"
                  >
                  <v-avatar>
                    <img
                      src="../../assets/md-account.png"
                    />
                  </v-avatar>
                <v-icon medium> mdi-pencil </v-icon>
                </v-btn>
              </template>
              <span>Upload New User Avatar</span>
              </v-tooltip>  
            </template>
          <upload-file-form 
            title="Upload New User Avatar"
            v-on:cancelentry="onCancel"
            v-on:saveentry="onSaveUploadFile"
          >
          </upload-file-form>
          </v-dialog>
<!-- *************** Edit Profile ******************  -->       
      <v-card>
        <span class="text-h2 darkgrey--text">Edit User Profile </span>
        <v-form>
          <v-container class="py-0">
            <v-row>
              <v-col
                cols="12"
                md="4"
              >
                <v-text-field
                  label="Organization/Family"
                  value="The Rogers"
                />
              </v-col>
              <v-col
                cols="12"
                md="4"
              >
                <v-text-field
                  class="purple-input"
                  label="User Name"
                  value="sarogersz"
                />
              </v-col>
              <v-col
                cols="12"
                md="4"
              >
                <v-text-field
                  label="Email Address"
                  class="purple-input"
                  value="sarogers@gorgelogic.com"
                />
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  label="First Name"
                  class="purple-input"
                  value="Stephen"
                />
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  label="Last Name"
                  class="purple-input"
                  value="Rogers"
                />
              </v-col>
              <v-col
                cols="12"
                class="text-right"
              >
                <v-btn
                  color="success"
                  class="mr-5"
                  to="/"
                >
                  Save Profile
                </v-btn>
                <v-btn
                  color="success"
                  class="mr-0"
                  to="/"
                >
                  Cancel
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card>
    </v-col>
  </v-row>
  </v-container>
</template>

<script>
/* eslint-disable */
//************************
// JAVASCRIPT
//************************
import UploadFileForm from '../../components/forms/UploadFileForm.vue'

export default {
  name: "UserProfile",
  components: {
    UploadFileForm
  },
//************************
// DATA
//************************
data () { return {
  dialog1: false,

} },
//************************
// METHODS
//************************
methods: {
  onEditItem(){
  },
// Cancel out the save settings dialog
  onCancel () {
    this.dialog1 = false
    },
  onSaveUploadFile() {

  },
  
},
//************************
// LIFECYCLE HOOKS
//************************
created () {
},

} // Export default end bracket

</script>
