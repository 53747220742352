<!-- eslint-disable -->
<template>
<!-- ***** START Upload File Form Dialog ***** -->
  <base-material-card
    color="blue"
    icon="mdi-upload"
    :title="title"
    class="px-5 py-3 mt-10"
  >
    <vue2-dropzone
      ref="dropzone"
      id="drop1"
      :options="dropzoneOptions"
      @vdropzone-complete="afterComplete"
      @vdropzone-max-files-exceeded="maxFilesExceeded"
      ></vue2-dropzone>

    <v-card-actions class="pl-0" >
      <v-spacer></v-spacer>
      <v-btn
        color="success"
        min-width="50"
        text 
        @click="onDismissUploadedFile"
        >
        Dismiss
      </v-btn>
      <v-btn
        color="success"
        min-width="50"
        text 
        @click="onSaveUploadedFile"
        >
        Save
      </v-btn>
      <v-btn
        color="red"
        min-width="25"
        text 
        @click="debuginstance()"
        >
        Debug
      </v-btn>
    </v-card-actions>
  </base-material-card>   
<!-- ***** END Upload File Form Dialog ***** -->
</template>

<script>
/* eslint-disable */
//************************
// JAVASCRIPT
//************************
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
let uuid = require("uuid")
export default {
  name: 'UploadFileForm',
  components: {
    vue2Dropzone
  },
  mixins: [],
  props: {
    title: {
      type: String,
      default: 'Upload New User Avatar'},
  },
//************************
// DATA
//************************
  data () { return {
    imagefile: null,
    dropzoneOptions: {
      url: 'http://localhost:5000/api/files/uploadimage',
      // url: 'https://httpbin.org/post',
      title: 'Upload New User Avatar',
      acceptedFiles: 'image/*',
      maxFiles: 1,
      thumbnailWidth: 150,
      thumbnailHeight: 150,
      addRemoveLinks: false,
      maxFilesize: 0.5,
      headers: { "My-Awesome-Header": "header value" }
    },
    showdialog: false,
    menu: false,
    modal: false,
    date: null,
    entry: { 
      de_savetime: this.saveTime,
      de_entrydate: null,
      de_entrytime: null,
  },
  }},
//************************
// WATCH
//************************
watch: {
},
//************************
// METHODS
//************************
methods: {
  debuginstance (val) {
    debugger
  },
//******** Replace current file with new one
maxFilesExceeded(file) {
  this.$refs.dropzone.removeAllFiles()
  this.$refs.dropzone.addFile(file)
  this.imagefile = file
},
//******** Called after file is uploaded to Dropzone
afterComplete(file) {
  console.log("file = ", file)

  try {
    // Get a unique filename
    const imageName = uuid.v1();

    var metaData = { contentType: "image/png" }
    // debugger
    } catch (err) {
      console.log(err.message);
  }
},

//******* Dismiss the upload form
  onDismissUploadedFile() {
    this.$refs.dropzone.removeAllFiles()
    this.$emit('cancelentry')
  },

//******* Initialize the form from props and the current date/time
  onResetForm () {
    // console.log("onResetForm")      
  }, 
//******* Save the entered readings
  async onSaveUploadedFile () {
  try {

    // sync with parent
    this.$emit('saveuploadedfile', file)
    this.showdialog = false
    } 
      catch(err) {
      this.error = err.message
      console.log('UploadFileForm: onSaveUploadedFile() (threw error)= ', this.error)
    }
  },

},
//***** NON-METHODS *******/
async mounted () {
  this.onResetForm()
},
}

</script>
